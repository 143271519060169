.info-card {
  background-color: rgba(255, 255, 255, 0.3);
  padding: 1rem 1.5rem;
  border-radius: 2rem;
  position: absolute;
  bottom: 5.5rem;
  right: -3rem;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  pointer-events: none;
}

.info-card.open {
  opacity: 1;
  pointer-events: all;
}

.info-card .icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}

.info-card svg {
  width: 2rem;
  height: 100%;
  fill: transparent;
}

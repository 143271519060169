#stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 167px 228px #fff, 1548px 1572px #fff, 639px 746px #fff,
    194px 1748px #fff, 1473px 1407px #fff, 235px 898px #fff, 1358px 240px #fff,
    474px 110px #fff, 1983px 1110px #fff, 28px 1929px #fff, 1261px 1773px #fff,
    1761px 408px #fff, 1311px 784px #fff, 1547px 460px #fff, 584px 1070px #fff,
    173px 470px #fff, 786px 425px #fff, 1494px 1619px #fff, 484px 1905px #fff,
    392px 166px #fff, 250px 717px #fff, 1026px 1147px #fff, 1476px 1736px #fff,
    1696px 1115px #fff, 105px 1450px #fff, 1888px 680px #fff, 1226px 1782px #fff,
    1947px 1312px #fff, 1135px 1895px #fff, 700px 1363px #fff, 854px 1816px #fff,
    102px 1595px #fff, 1356px 248px #fff, 630px 1392px #fff, 232px 1044px #fff,
    1678px 864px #fff, 1575px 1070px #fff, 415px 1758px #fff, 969px 416px #fff,
    974px 997px #fff, 1122px 488px #fff, 364px 892px #fff, 524px 1393px #fff,
    1450px 25px #fff, 1913px 1969px #fff, 382px 891px #fff, 668px 969px #fff,
    1636px 613px #fff, 1472px 1025px #fff, 1207px 269px #fff, 1468px 535px #fff,
    1774px 1779px #fff, 1715px 299px #fff, 1059px 888px #fff, 1421px 140px #fff,
    1133px 632px #fff, 1029px 500px #fff, 1330px 353px #fff, 1423px 1555px #fff,
    1036px 723px #fff, 1314px 49px #fff, 1592px 432px #fff, 113px 1755px #fff,
    1328px 340px #fff, 609px 941px #fff, 285px 1644px #fff, 92px 366px #fff,
    1673px 1359px #fff, 1051px 720px #fff, 782px 1687px #fff, 1310px 1260px #fff,
    752px 1455px #fff, 1337px 966px #fff, 1517px 676px #fff, 1236px 1653px #fff,
    975px 542px #fff, 1079px 1913px #fff, 116px 1156px #fff, 1793px 1062px #fff,
    1588px 1157px #fff, 1361px 185px #fff, 1419px 1695px #fff, 997px 882px #fff,
    890px 94px #fff, 1383px 1242px #fff, 507px 1794px #fff, 1755px 1987px #fff,
    564px 1376px #fff, 1250px 1163px #fff, 1978px 1859px #fff, 780px 1164px #fff,
    495px 1548px #fff, 467px 971px #fff, 873px 1940px #fff, 808px 870px #fff,
    293px 56px #fff, 1738px 1648px #fff, 842px 34px #fff, 1424px 190px #fff,
    1479px 1941px #fff, 401px 624px #fff, 517px 843px #fff, 1273px 1234px #fff,
    119px 574px #fff, 1345px 503px #fff, 1380px 1166px #fff, 1517px 303px #fff,
    1163px 1489px #fff, 1650px 637px #fff, 1679px 662px #fff, 238px 909px #fff,
    1511px 135px #fff, 540px 1240px #fff, 1949px 1603px #fff, 1268px 1852px #fff,
    108px 103px #fff, 1455px 105px #fff, 619px 612px #fff, 983px 158px #fff,
    860px 208px #fff, 905px 661px #fff, 1790px 269px #fff, 373px 720px #fff,
    1392px 304px #fff, 270px 1934px #fff, 796px 153px #fff, 456px 1085px #fff,
    29px 1318px #fff, 1152px 1488px #fff, 424px 794px #fff, 1405px 722px #fff,
    427px 1879px #fff, 1655px 905px #fff, 515px 178px #fff, 773px 194px #fff,
    1208px 423px #fff, 1459px 556px #fff, 1136px 660px #fff, 404px 1847px #fff,
    854px 1265px #fff, 773px 1548px #fff, 393px 1853px #fff, 1049px 1622px #fff,
    1324px 294px #fff, 962px 1700px #fff, 1934px 235px #fff, 636px 1901px #fff,
    99px 422px #fff, 594px 1655px #fff, 606px 187px #fff, 1597px 1625px #fff,
    703px 1561px #fff, 580px 1397px #fff, 633px 526px #fff, 1188px 1631px #fff,
    1710px 611px #fff, 1642px 955px #fff, 1183px 969px #fff, 1023px 1582px #fff,
    618px 1877px #fff, 1939px 165px #fff, 1464px 1284px #fff, 218px 1668px #fff,
    1667px 624px #fff, 1039px 353px #fff, 145px 1432px #fff, 1103px 1794px #fff,
    55px 878px #fff, 1358px 1537px #fff, 137px 1050px #fff, 1533px 1673px #fff,
    594px 693px #fff, 1395px 34px #fff, 1871px 1775px #fff, 173px 38px #fff,
    424px 1627px #fff, 311px 1012px #fff, 1992px 155px #fff, 1169px 1556px #fff,
    206px 1246px #fff, 1141px 874px #fff, 1570px 262px #fff, 719px 754px #fff,
    1640px 516px #fff, 1478px 1893px #fff, 1913px 731px #fff, 429px 1834px #fff,
    395px 1336px #fff, 1132px 1132px #fff, 593px 107px #fff, 1087px 200px #fff,
    67px 1900px #fff, 1412px 194px #fff, 1464px 995px #fff, 1275px 1561px #fff,
    550px 1022px #fff, 503px 53px #fff, 555px 150px #fff, 66px 390px #fff,
    586px 741px #fff, 287px 560px #fff, 693px 908px #fff, 14px 74px #fff,
    1357px 913px #fff, 1014px 16px #fff, 1603px 1103px #fff, 819px 345px #fff,
    374px 848px #fff, 1534px 393px #fff, 485px 403px #fff, 1585px 1698px #fff,
    1130px 1304px #fff, 1184px 519px #fff, 1043px 1076px #fff, 1124px 736px #fff,
    1215px 246px #fff, 1174px 1301px #fff, 333px 779px #fff, 1729px 113px #fff,
    167px 1321px #fff, 1900px 677px #fff, 328px 765px #fff, 1564px 1113px #fff,
    1382px 1449px #fff, 810px 346px #fff, 1882px 692px #fff, 1323px 796px #fff,
    1845px 1848px #fff, 47px 579px #fff, 501px 1717px #fff, 749px 283px #fff,
    570px 55px #fff, 486px 1989px #fff, 1852px 355px #fff, 385px 803px #fff,
    951px 1826px #fff, 1374px 1276px #fff, 1512px 880px #fff, 765px 1984px #fff,
    201px 1568px #fff, 1309px 1370px #fff, 1256px 1371px #fff,
    1113px 1805px #fff, 228px 1861px #fff, 1756px 228px #fff, 1271px 831px #fff,
    1347px 1841px #fff, 447px 1877px #fff, 830px 1395px #fff, 949px 956px #fff,
    772px 207px #fff, 866px 1192px #fff, 1282px 63px #fff, 532px 688px #fff,
    970px 669px #fff, 797px 1604px #fff, 1024px 456px #fff, 1739px 345px #fff,
    588px 1848px #fff, 1225px 963px #fff, 280px 1134px #fff, 1779px 1375px #fff,
    410px 211px #fff, 1525px 1646px #fff, 533px 1957px #fff, 844px 1951px #fff,
    1775px 1443px #fff, 1636px 1983px #fff, 567px 720px #fff, 305px 295px #fff,
    33px 708px #fff, 276px 410px #fff, 1699px 293px #fff, 939px 1473px #fff,
    1126px 1601px #fff, 1748px 835px #fff, 1439px 1374px #fff, 465px 1250px #fff,
    754px 369px #fff, 934px 441px #fff, 62px 158px #fff, 1792px 1828px #fff,
    482px 357px #fff, 856px 764px #fff, 1847px 1274px #fff, 1066px 1819px #fff,
    1627px 877px #fff, 335px 33px #fff, 1686px 1269px #fff, 1363px 1870px #fff,
    545px 399px #fff, 1555px 1773px #fff, 1713px 119px #fff, 1511px 1051px #fff,
    1431px 157px #fff, 978px 718px #fff, 1545px 1505px #fff, 197px 413px #fff,
    725px 907px #fff, 155px 1683px #fff, 1870px 965px #fff, 1109px 1302px #fff,
    297px 1716px #fff, 937px 254px #fff, 1431px 1032px #fff, 878px 359px #fff,
    517px 1086px #fff, 1171px 63px #fff, 234px 117px #fff, 216px 32px #fff,
    619px 1194px #fff, 693px 1813px #fff, 1523px 540px #fff, 18px 813px #fff,
    741px 1126px #fff, 884px 1571px #fff, 576px 1498px #fff, 588px 978px #fff,
    1434px 1862px #fff, 1994px 1817px #fff, 538px 876px #fff, 137px 1344px #fff,
    757px 676px #fff, 1903px 1938px #fff, 691px 1520px #fff, 1525px 886px #fff,
    470px 673px #fff, 671px 1759px #fff, 1064px 442px #fff, 1269px 820px #fff,
    234px 1226px #fff, 1375px 280px #fff, 407px 1821px #fff, 1129px 1664px #fff,
    996px 234px #fff, 840px 1234px #fff, 1306px 304px #fff, 450px 1921px #fff,
    759px 1196px #fff, 329px 207px #fff, 1885px 1074px #fff, 491px 105px #fff,
    1826px 1976px #fff, 1947px 1106px #fff, 1324px 1038px #fff, 837px 17px #fff,
    509px 1062px #fff, 925px 1533px #fff, 1124px 130px #fff, 1568px 686px #fff,
    1617px 418px #fff, 1694px 835px #fff, 1976px 812px #fff, 925px 1928px #fff,
    1376px 464px #fff, 428px 1108px #fff, 1340px 619px #fff, 93px 628px #fff,
    1315px 1335px #fff, 1832px 1620px #fff, 1033px 967px #fff, 1407px 145px #fff,
    1698px 1162px #fff, 1652px 839px #fff, 923px 139px #fff, 789px 1346px #fff,
    662px 1030px #fff, 1649px 335px #fff, 719px 244px #fff, 1447px 435px #fff,
    423px 713px #fff, 924px 106px #fff, 1337px 526px #fff, 1910px 337px #fff,
    241px 244px #fff, 1338px 1289px #fff, 1979px 279px #fff, 1878px 1531px #fff,
    367px 1360px #fff, 1989px 871px #fff, 697px 901px #fff, 90px 101px #fff,
    1636px 1392px #fff, 1540px 471px #fff, 700px 869px #fff, 1594px 1550px #fff,
    179px 1470px #fff, 108px 1259px #fff, 1830px 1614px #fff, 220px 1598px #fff,
    1975px 1575px #fff, 51px 1324px #fff, 1895px 1914px #fff, 1887px 334px #fff,
    1474px 401px #fff, 394px 1908px #fff, 1024px 1857px #fff, 1456px 490px #fff,
    12px 1840px #fff, 836px 692px #fff, 462px 1719px #fff, 1650px 1836px #fff,
    1185px 141px #fff, 189px 1499px #fff, 1540px 15px #fff, 153px 183px #fff,
    227px 650px #fff, 974px 610px #fff, 256px 624px #fff, 522px 1915px #fff,
    383px 750px #fff, 1214px 1977px #fff, 1490px 1783px #fff, 1188px 1990px #fff,
    1590px 1253px #fff, 439px 479px #fff, 32px 766px #fff, 994px 1143px #fff,
    1229px 14px #fff, 197px 304px #fff, 151px 1568px #fff, 1984px 97px #fff,
    1440px 1841px #fff, 1804px 738px #fff, 287px 1119px #fff, 244px 218px #fff,
    694px 886px #fff, 585px 1412px #fff, 1410px 1009px #fff, 874px 778px #fff,
    918px 1179px #fff, 460px 600px #fff, 1941px 27px #fff, 1570px 374px #fff,
    1929px 1022px #fff, 700px 1343px #fff, 229px 180px #fff, 568px 1783px #fff,
    1860px 710px #fff, 71px 778px #fff, 1950px 1350px #fff, 1785px 661px #fff,
    187px 1188px #fff, 1732px 1145px #fff, 1084px 93px #fff, 1626px 141px #fff,
    330px 545px #fff, 753px 422px #fff, 1499px 297px #fff, 1617px 1205px #fff,
    28px 832px #fff, 931px 1377px #fff, 941px 563px #fff, 718px 1974px #fff,
    1949px 1397px #fff, 1883px 1127px #fff, 959px 673px #fff, 1018px 1617px #fff,
    1137px 703px #fff, 457px 17px #fff, 1315px 437px #fff, 527px 428px #fff,
    401px 580px #fff, 570px 1121px #fff, 674px 764px #fff, 6px 1187px #fff,
    1870px 1907px #fff, 1264px 880px #fff, 301px 94px #fff, 1979px 306px #fff,
    529px 1728px #fff, 178px 320px #fff, 391px 537px #fff, 1314px 1223px #fff,
    669px 540px #fff, 1919px 1014px #fff, 638px 1840px #fff, 1001px 388px #fff,
    146px 867px #fff, 1602px 1812px #fff, 941px 892px #fff, 1009px 876px #fff,
    299px 513px #fff, 1002px 1306px #fff, 992px 1098px #fff, 287px 1450px #fff,
    1947px 1129px #fff, 973px 226px #fff, 1789px 1624px #fff, 1941px 1342px #fff,
    543px 1434px #fff, 1899px 1783px #fff, 1187px 1488px #fff, 704px 1671px #fff,
    1129px 10px #fff, 1147px 1895px #fff, 1958px 722px #fff, 1213px 462px #fff,
    1548px 909px #fff, 802px 354px #fff, 715px 235px #fff, 576px 652px #fff,
    1689px 428px #fff, 1690px 1570px #fff, 221px 608px #fff, 454px 177px #fff,
    112px 786px #fff, 1188px 783px #fff, 1815px 574px #fff, 1547px 352px #fff,
    803px 737px #fff, 692px 434px #fff, 1355px 1401px #fff, 1136px 1537px #fff,
    1222px 1644px #fff, 1766px 1187px #fff, 360px 1274px #fff, 1096px 958px #fff,
    510px 240px #fff, 1222px 1431px #fff, 1719px 1712px #fff, 1122px 200px #fff,
    49px 1575px #fff, 656px 653px #fff, 302px 409px #fff, 1118px 888px #fff,
    1086px 78px #fff, 83px 1496px #fff, 1490px 680px #fff, 228px 712px #fff,
    697px 1390px #fff, 1125px 42px #fff, 1104px 1527px #fff, 218px 1960px #fff,
    1021px 1971px #fff, 1728px 587px #fff, 1636px 1115px #fff, 816px 487px #fff,
    895px 469px #fff, 847px 966px #fff, 10px 882px #fff, 954px 1405px #fff,
    409px 406px #fff, 1896px 987px #fff, 468px 1279px #fff, 1716px 1852px #fff,
    1218px 463px #fff, 150px 149px #fff, 383px 331px #fff, 1652px 1375px #fff,
    1623px 640px #fff, 1516px 29px #fff, 1750px 512px #fff, 1376px 229px #fff,
    1266px 646px #fff, 1809px 240px #fff, 1949px 206px #fff, 1074px 284px #fff,
    151px 1348px #fff, 1781px 1039px #fff, 1048px 665px #fff, 1642px 1909px #fff,
    920px 348px #fff, 1258px 1133px #fff, 912px 1881px #fff, 1699px 1859px #fff,
    873px 1360px #fff, 1800px 1300px #fff, 1077px 1730px #fff, 1062px 385px #fff,
    51px 1317px #fff, 887px 1246px #fff, 100px 675px #fff, 141px 433px #fff,
    1646px 1351px #fff, 835px 875px #fff, 1792px 1696px #fff, 578px 805px #fff,
    531px 230px #fff, 735px 1774px #fff, 203px 236px #fff, 1828px 751px #fff,
    597px 403px #fff, 963px 1036px #fff, 84px 918px #fff, 1001px 49px #fff,
    896px 1726px #fff, 240px 25px #fff, 209px 440px #fff, 226px 1137px #fff,
    1711px 1514px #fff, 289px 299px #fff, 1759px 1597px #fff, 334px 399px #fff,
    439px 319px #fff, 958px 703px #fff, 729px 1112px #fff, 588px 800px #fff,
    68px 1949px #fff, 1137px 1624px #fff, 626px 233px #fff, 1843px 1143px #fff,
    598px 1703px #fff, 1758px 1524px #fff, 1831px 1859px #fff, 1237px 169px #fff,
    810px 180px #fff, 1514px 353px #fff, 1590px 1164px #fff, 1830px 965px #fff,
    126px 686px #fff, 1443px 1467px #fff, 1684px 299px #fff, 1950px 1692px #fff,
    577px 1553px #fff, 1012px 801px #fff, 1232px 402px #fff, 933px 1240px #fff,
    165px 525px #fff, 759px 1523px #fff, 291px 1004px #fff, 1453px 1095px #fff,
    1763px 1577px #fff, 588px 1773px #fff, 244px 454px #fff, 1176px 57px #fff,
    1808px 1343px #fff, 972px 561px #fff, 1011px 1586px #fff, 1378px 1040px #fff,
    1950px 796px #fff, 323px 466px #fff, 1007px 1152px #fff, 446px 1736px #fff,
    1151px 530px #fff, 1523px 1207px #fff, 443px 1033px #fff, 252px 1228px #fff,
    1405px 369px #fff, 894px 1837px #fff, 589px 139px #fff, 1115px 1669px #fff,
    1339px 1210px #fff, 760px 1686px #fff, 315px 548px #fff, 769px 586px #fff,
    1648px 1491px #fff, 1490px 451px #fff, 1932px 1514px #fff,
    1901px 1254px #fff, 1633px 1143px #fff, 1540px 735px #fff, 951px 1701px #fff,
    1652px 1153px #fff, 1589px 930px #fff, 728px 1742px #fff, 1502px 1635px #fff,
    861px 1014px #fff, 372px 1935px #fff, 1987px 1260px #fff, 190px 1307px #fff,
    1499px 631px #fff, 1401px 82px #fff, 1642px 1573px #fff, 579px 380px #fff,
    128px 1278px #fff, 901px 345px #fff, 585px 177px #fff, 473px 1430px #fff,
    1244px 901px #fff, 540px 1712px #fff, 1287px 1605px #fff, 491px 332px #fff,
    1075px 418px #fff, 1053px 1465px #fff, 582px 1027px #fff, 1050px 102px #fff,
    99px 139px #fff, 1953px 1958px #fff, 1008px 437px #fff, 308px 1241px #fff,
    183px 1387px #fff, 1474px 1538px #fff, 446px 1484px #fff, 1764px 1386px #fff,
    477px 483px #fff, 1658px 1685px #fff, 1809px 1269px #fff, 1677px 1970px #fff,
    1217px 1095px #fff, 1566px 1080px #fff, 1601px 1170px #fff,
    1078px 707px #fff, 223px 260px #fff, 1620px 781px #fff, 811px 453px #fff,
    393px 1875px #fff, 841px 1493px #fff, 911px 201px #fff, 858px 516px #fff;
  animation: animStar 50s linear infinite;
}
#stars:after {
  content: ' ';
  position: absolute;
  top: 2000px;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 167px 228px #fff, 1548px 1572px #fff, 639px 746px #fff,
    194px 1748px #fff, 1473px 1407px #fff, 235px 898px #fff, 1358px 240px #fff,
    474px 110px #fff, 1983px 1110px #fff, 28px 1929px #fff, 1261px 1773px #fff,
    1761px 408px #fff, 1311px 784px #fff, 1547px 460px #fff, 584px 1070px #fff,
    173px 470px #fff, 786px 425px #fff, 1494px 1619px #fff, 484px 1905px #fff,
    392px 166px #fff, 250px 717px #fff, 1026px 1147px #fff, 1476px 1736px #fff,
    1696px 1115px #fff, 105px 1450px #fff, 1888px 680px #fff, 1226px 1782px #fff,
    1947px 1312px #fff, 1135px 1895px #fff, 700px 1363px #fff, 854px 1816px #fff,
    102px 1595px #fff, 1356px 248px #fff, 630px 1392px #fff, 232px 1044px #fff,
    1678px 864px #fff, 1575px 1070px #fff, 415px 1758px #fff, 969px 416px #fff,
    974px 997px #fff, 1122px 488px #fff, 364px 892px #fff, 524px 1393px #fff,
    1450px 25px #fff, 1913px 1969px #fff, 382px 891px #fff, 668px 969px #fff,
    1636px 613px #fff, 1472px 1025px #fff, 1207px 269px #fff, 1468px 535px #fff,
    1774px 1779px #fff, 1715px 299px #fff, 1059px 888px #fff, 1421px 140px #fff,
    1133px 632px #fff, 1029px 500px #fff, 1330px 353px #fff, 1423px 1555px #fff,
    1036px 723px #fff, 1314px 49px #fff, 1592px 432px #fff, 113px 1755px #fff,
    1328px 340px #fff, 609px 941px #fff, 285px 1644px #fff, 92px 366px #fff,
    1673px 1359px #fff, 1051px 720px #fff, 782px 1687px #fff, 1310px 1260px #fff,
    752px 1455px #fff, 1337px 966px #fff, 1517px 676px #fff, 1236px 1653px #fff,
    975px 542px #fff, 1079px 1913px #fff, 116px 1156px #fff, 1793px 1062px #fff,
    1588px 1157px #fff, 1361px 185px #fff, 1419px 1695px #fff, 997px 882px #fff,
    890px 94px #fff, 1383px 1242px #fff, 507px 1794px #fff, 1755px 1987px #fff,
    564px 1376px #fff, 1250px 1163px #fff, 1978px 1859px #fff, 780px 1164px #fff,
    495px 1548px #fff, 467px 971px #fff, 873px 1940px #fff, 808px 870px #fff,
    293px 56px #fff, 1738px 1648px #fff, 842px 34px #fff, 1424px 190px #fff,
    1479px 1941px #fff, 401px 624px #fff, 517px 843px #fff, 1273px 1234px #fff,
    119px 574px #fff, 1345px 503px #fff, 1380px 1166px #fff, 1517px 303px #fff,
    1163px 1489px #fff, 1650px 637px #fff, 1679px 662px #fff, 238px 909px #fff,
    1511px 135px #fff, 540px 1240px #fff, 1949px 1603px #fff, 1268px 1852px #fff,
    108px 103px #fff, 1455px 105px #fff, 619px 612px #fff, 983px 158px #fff,
    860px 208px #fff, 905px 661px #fff, 1790px 269px #fff, 373px 720px #fff,
    1392px 304px #fff, 270px 1934px #fff, 796px 153px #fff, 456px 1085px #fff,
    29px 1318px #fff, 1152px 1488px #fff, 424px 794px #fff, 1405px 722px #fff,
    427px 1879px #fff, 1655px 905px #fff, 515px 178px #fff, 773px 194px #fff,
    1208px 423px #fff, 1459px 556px #fff, 1136px 660px #fff, 404px 1847px #fff,
    854px 1265px #fff, 773px 1548px #fff, 393px 1853px #fff, 1049px 1622px #fff,
    1324px 294px #fff, 962px 1700px #fff, 1934px 235px #fff, 636px 1901px #fff,
    99px 422px #fff, 594px 1655px #fff, 606px 187px #fff, 1597px 1625px #fff,
    703px 1561px #fff, 580px 1397px #fff, 633px 526px #fff, 1188px 1631px #fff,
    1710px 611px #fff, 1642px 955px #fff, 1183px 969px #fff, 1023px 1582px #fff,
    618px 1877px #fff, 1939px 165px #fff, 1464px 1284px #fff, 218px 1668px #fff,
    1667px 624px #fff, 1039px 353px #fff, 145px 1432px #fff, 1103px 1794px #fff,
    55px 878px #fff, 1358px 1537px #fff, 137px 1050px #fff, 1533px 1673px #fff,
    594px 693px #fff, 1395px 34px #fff, 1871px 1775px #fff, 173px 38px #fff,
    424px 1627px #fff, 311px 1012px #fff, 1992px 155px #fff, 1169px 1556px #fff,
    206px 1246px #fff, 1141px 874px #fff, 1570px 262px #fff, 719px 754px #fff,
    1640px 516px #fff, 1478px 1893px #fff, 1913px 731px #fff, 429px 1834px #fff,
    395px 1336px #fff, 1132px 1132px #fff, 593px 107px #fff, 1087px 200px #fff,
    67px 1900px #fff, 1412px 194px #fff, 1464px 995px #fff, 1275px 1561px #fff,
    550px 1022px #fff, 503px 53px #fff, 555px 150px #fff, 66px 390px #fff,
    586px 741px #fff, 287px 560px #fff, 693px 908px #fff, 14px 74px #fff,
    1357px 913px #fff, 1014px 16px #fff, 1603px 1103px #fff, 819px 345px #fff,
    374px 848px #fff, 1534px 393px #fff, 485px 403px #fff, 1585px 1698px #fff,
    1130px 1304px #fff, 1184px 519px #fff, 1043px 1076px #fff, 1124px 736px #fff,
    1215px 246px #fff, 1174px 1301px #fff, 333px 779px #fff, 1729px 113px #fff,
    167px 1321px #fff, 1900px 677px #fff, 328px 765px #fff, 1564px 1113px #fff,
    1382px 1449px #fff, 810px 346px #fff, 1882px 692px #fff, 1323px 796px #fff,
    1845px 1848px #fff, 47px 579px #fff, 501px 1717px #fff, 749px 283px #fff,
    570px 55px #fff, 486px 1989px #fff, 1852px 355px #fff, 385px 803px #fff,
    951px 1826px #fff, 1374px 1276px #fff, 1512px 880px #fff, 765px 1984px #fff,
    201px 1568px #fff, 1309px 1370px #fff, 1256px 1371px #fff,
    1113px 1805px #fff, 228px 1861px #fff, 1756px 228px #fff, 1271px 831px #fff,
    1347px 1841px #fff, 447px 1877px #fff, 830px 1395px #fff, 949px 956px #fff,
    772px 207px #fff, 866px 1192px #fff, 1282px 63px #fff, 532px 688px #fff,
    970px 669px #fff, 797px 1604px #fff, 1024px 456px #fff, 1739px 345px #fff,
    588px 1848px #fff, 1225px 963px #fff, 280px 1134px #fff, 1779px 1375px #fff,
    410px 211px #fff, 1525px 1646px #fff, 533px 1957px #fff, 844px 1951px #fff,
    1775px 1443px #fff, 1636px 1983px #fff, 567px 720px #fff, 305px 295px #fff,
    33px 708px #fff, 276px 410px #fff, 1699px 293px #fff, 939px 1473px #fff,
    1126px 1601px #fff, 1748px 835px #fff, 1439px 1374px #fff, 465px 1250px #fff,
    754px 369px #fff, 934px 441px #fff, 62px 158px #fff, 1792px 1828px #fff,
    482px 357px #fff, 856px 764px #fff, 1847px 1274px #fff, 1066px 1819px #fff,
    1627px 877px #fff, 335px 33px #fff, 1686px 1269px #fff, 1363px 1870px #fff,
    545px 399px #fff, 1555px 1773px #fff, 1713px 119px #fff, 1511px 1051px #fff,
    1431px 157px #fff, 978px 718px #fff, 1545px 1505px #fff, 197px 413px #fff,
    725px 907px #fff, 155px 1683px #fff, 1870px 965px #fff, 1109px 1302px #fff,
    297px 1716px #fff, 937px 254px #fff, 1431px 1032px #fff, 878px 359px #fff,
    517px 1086px #fff, 1171px 63px #fff, 234px 117px #fff, 216px 32px #fff,
    619px 1194px #fff, 693px 1813px #fff, 1523px 540px #fff, 18px 813px #fff,
    741px 1126px #fff, 884px 1571px #fff, 576px 1498px #fff, 588px 978px #fff,
    1434px 1862px #fff, 1994px 1817px #fff, 538px 876px #fff, 137px 1344px #fff,
    757px 676px #fff, 1903px 1938px #fff, 691px 1520px #fff, 1525px 886px #fff,
    470px 673px #fff, 671px 1759px #fff, 1064px 442px #fff, 1269px 820px #fff,
    234px 1226px #fff, 1375px 280px #fff, 407px 1821px #fff, 1129px 1664px #fff,
    996px 234px #fff, 840px 1234px #fff, 1306px 304px #fff, 450px 1921px #fff,
    759px 1196px #fff, 329px 207px #fff, 1885px 1074px #fff, 491px 105px #fff,
    1826px 1976px #fff, 1947px 1106px #fff, 1324px 1038px #fff, 837px 17px #fff,
    509px 1062px #fff, 925px 1533px #fff, 1124px 130px #fff, 1568px 686px #fff,
    1617px 418px #fff, 1694px 835px #fff, 1976px 812px #fff, 925px 1928px #fff,
    1376px 464px #fff, 428px 1108px #fff, 1340px 619px #fff, 93px 628px #fff,
    1315px 1335px #fff, 1832px 1620px #fff, 1033px 967px #fff, 1407px 145px #fff,
    1698px 1162px #fff, 1652px 839px #fff, 923px 139px #fff, 789px 1346px #fff,
    662px 1030px #fff, 1649px 335px #fff, 719px 244px #fff, 1447px 435px #fff,
    423px 713px #fff, 924px 106px #fff, 1337px 526px #fff, 1910px 337px #fff,
    241px 244px #fff, 1338px 1289px #fff, 1979px 279px #fff, 1878px 1531px #fff,
    367px 1360px #fff, 1989px 871px #fff, 697px 901px #fff, 90px 101px #fff,
    1636px 1392px #fff, 1540px 471px #fff, 700px 869px #fff, 1594px 1550px #fff,
    179px 1470px #fff, 108px 1259px #fff, 1830px 1614px #fff, 220px 1598px #fff,
    1975px 1575px #fff, 51px 1324px #fff, 1895px 1914px #fff, 1887px 334px #fff,
    1474px 401px #fff, 394px 1908px #fff, 1024px 1857px #fff, 1456px 490px #fff,
    12px 1840px #fff, 836px 692px #fff, 462px 1719px #fff, 1650px 1836px #fff,
    1185px 141px #fff, 189px 1499px #fff, 1540px 15px #fff, 153px 183px #fff,
    227px 650px #fff, 974px 610px #fff, 256px 624px #fff, 522px 1915px #fff,
    383px 750px #fff, 1214px 1977px #fff, 1490px 1783px #fff, 1188px 1990px #fff,
    1590px 1253px #fff, 439px 479px #fff, 32px 766px #fff, 994px 1143px #fff,
    1229px 14px #fff, 197px 304px #fff, 151px 1568px #fff, 1984px 97px #fff,
    1440px 1841px #fff, 1804px 738px #fff, 287px 1119px #fff, 244px 218px #fff,
    694px 886px #fff, 585px 1412px #fff, 1410px 1009px #fff, 874px 778px #fff,
    918px 1179px #fff, 460px 600px #fff, 1941px 27px #fff, 1570px 374px #fff,
    1929px 1022px #fff, 700px 1343px #fff, 229px 180px #fff, 568px 1783px #fff,
    1860px 710px #fff, 71px 778px #fff, 1950px 1350px #fff, 1785px 661px #fff,
    187px 1188px #fff, 1732px 1145px #fff, 1084px 93px #fff, 1626px 141px #fff,
    330px 545px #fff, 753px 422px #fff, 1499px 297px #fff, 1617px 1205px #fff,
    28px 832px #fff, 931px 1377px #fff, 941px 563px #fff, 718px 1974px #fff,
    1949px 1397px #fff, 1883px 1127px #fff, 959px 673px #fff, 1018px 1617px #fff,
    1137px 703px #fff, 457px 17px #fff, 1315px 437px #fff, 527px 428px #fff,
    401px 580px #fff, 570px 1121px #fff, 674px 764px #fff, 6px 1187px #fff,
    1870px 1907px #fff, 1264px 880px #fff, 301px 94px #fff, 1979px 306px #fff,
    529px 1728px #fff, 178px 320px #fff, 391px 537px #fff, 1314px 1223px #fff,
    669px 540px #fff, 1919px 1014px #fff, 638px 1840px #fff, 1001px 388px #fff,
    146px 867px #fff, 1602px 1812px #fff, 941px 892px #fff, 1009px 876px #fff,
    299px 513px #fff, 1002px 1306px #fff, 992px 1098px #fff, 287px 1450px #fff,
    1947px 1129px #fff, 973px 226px #fff, 1789px 1624px #fff, 1941px 1342px #fff,
    543px 1434px #fff, 1899px 1783px #fff, 1187px 1488px #fff, 704px 1671px #fff,
    1129px 10px #fff, 1147px 1895px #fff, 1958px 722px #fff, 1213px 462px #fff,
    1548px 909px #fff, 802px 354px #fff, 715px 235px #fff, 576px 652px #fff,
    1689px 428px #fff, 1690px 1570px #fff, 221px 608px #fff, 454px 177px #fff,
    112px 786px #fff, 1188px 783px #fff, 1815px 574px #fff, 1547px 352px #fff,
    803px 737px #fff, 692px 434px #fff, 1355px 1401px #fff, 1136px 1537px #fff,
    1222px 1644px #fff, 1766px 1187px #fff, 360px 1274px #fff, 1096px 958px #fff,
    510px 240px #fff, 1222px 1431px #fff, 1719px 1712px #fff, 1122px 200px #fff,
    49px 1575px #fff, 656px 653px #fff, 302px 409px #fff, 1118px 888px #fff,
    1086px 78px #fff, 83px 1496px #fff, 1490px 680px #fff, 228px 712px #fff,
    697px 1390px #fff, 1125px 42px #fff, 1104px 1527px #fff, 218px 1960px #fff,
    1021px 1971px #fff, 1728px 587px #fff, 1636px 1115px #fff, 816px 487px #fff,
    895px 469px #fff, 847px 966px #fff, 10px 882px #fff, 954px 1405px #fff,
    409px 406px #fff, 1896px 987px #fff, 468px 1279px #fff, 1716px 1852px #fff,
    1218px 463px #fff, 150px 149px #fff, 383px 331px #fff, 1652px 1375px #fff,
    1623px 640px #fff, 1516px 29px #fff, 1750px 512px #fff, 1376px 229px #fff,
    1266px 646px #fff, 1809px 240px #fff, 1949px 206px #fff, 1074px 284px #fff,
    151px 1348px #fff, 1781px 1039px #fff, 1048px 665px #fff, 1642px 1909px #fff,
    920px 348px #fff, 1258px 1133px #fff, 912px 1881px #fff, 1699px 1859px #fff,
    873px 1360px #fff, 1800px 1300px #fff, 1077px 1730px #fff, 1062px 385px #fff,
    51px 1317px #fff, 887px 1246px #fff, 100px 675px #fff, 141px 433px #fff,
    1646px 1351px #fff, 835px 875px #fff, 1792px 1696px #fff, 578px 805px #fff,
    531px 230px #fff, 735px 1774px #fff, 203px 236px #fff, 1828px 751px #fff,
    597px 403px #fff, 963px 1036px #fff, 84px 918px #fff, 1001px 49px #fff,
    896px 1726px #fff, 240px 25px #fff, 209px 440px #fff, 226px 1137px #fff,
    1711px 1514px #fff, 289px 299px #fff, 1759px 1597px #fff, 334px 399px #fff,
    439px 319px #fff, 958px 703px #fff, 729px 1112px #fff, 588px 800px #fff,
    68px 1949px #fff, 1137px 1624px #fff, 626px 233px #fff, 1843px 1143px #fff,
    598px 1703px #fff, 1758px 1524px #fff, 1831px 1859px #fff, 1237px 169px #fff,
    810px 180px #fff, 1514px 353px #fff, 1590px 1164px #fff, 1830px 965px #fff,
    126px 686px #fff, 1443px 1467px #fff, 1684px 299px #fff, 1950px 1692px #fff,
    577px 1553px #fff, 1012px 801px #fff, 1232px 402px #fff, 933px 1240px #fff,
    165px 525px #fff, 759px 1523px #fff, 291px 1004px #fff, 1453px 1095px #fff,
    1763px 1577px #fff, 588px 1773px #fff, 244px 454px #fff, 1176px 57px #fff,
    1808px 1343px #fff, 972px 561px #fff, 1011px 1586px #fff, 1378px 1040px #fff,
    1950px 796px #fff, 323px 466px #fff, 1007px 1152px #fff, 446px 1736px #fff,
    1151px 530px #fff, 1523px 1207px #fff, 443px 1033px #fff, 252px 1228px #fff,
    1405px 369px #fff, 894px 1837px #fff, 589px 139px #fff, 1115px 1669px #fff,
    1339px 1210px #fff, 760px 1686px #fff, 315px 548px #fff, 769px 586px #fff,
    1648px 1491px #fff, 1490px 451px #fff, 1932px 1514px #fff,
    1901px 1254px #fff, 1633px 1143px #fff, 1540px 735px #fff, 951px 1701px #fff,
    1652px 1153px #fff, 1589px 930px #fff, 728px 1742px #fff, 1502px 1635px #fff,
    861px 1014px #fff, 372px 1935px #fff, 1987px 1260px #fff, 190px 1307px #fff,
    1499px 631px #fff, 1401px 82px #fff, 1642px 1573px #fff, 579px 380px #fff,
    128px 1278px #fff, 901px 345px #fff, 585px 177px #fff, 473px 1430px #fff,
    1244px 901px #fff, 540px 1712px #fff, 1287px 1605px #fff, 491px 332px #fff,
    1075px 418px #fff, 1053px 1465px #fff, 582px 1027px #fff, 1050px 102px #fff,
    99px 139px #fff, 1953px 1958px #fff, 1008px 437px #fff, 308px 1241px #fff,
    183px 1387px #fff, 1474px 1538px #fff, 446px 1484px #fff, 1764px 1386px #fff,
    477px 483px #fff, 1658px 1685px #fff, 1809px 1269px #fff, 1677px 1970px #fff,
    1217px 1095px #fff, 1566px 1080px #fff, 1601px 1170px #fff,
    1078px 707px #fff, 223px 260px #fff, 1620px 781px #fff, 811px 453px #fff,
    393px 1875px #fff, 841px 1493px #fff, 911px 201px #fff, 858px 516px #fff;
}

#stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 645px 82px #fff, 1630px 477px #fff, 881px 1908px #fff,
    1673px 334px #fff, 740px 1761px #fff, 52px 1648px #fff, 34px 1902px #fff,
    767px 1393px #fff, 1278px 788px #fff, 456px 632px #fff, 1764px 470px #fff,
    311px 1276px #fff, 1205px 433px #fff, 21px 962px #fff, 1821px 575px #fff,
    1968px 1192px #fff, 1271px 1939px #fff, 1009px 712px #fff,
    1076px 1033px #fff, 712px 852px #fff, 1817px 1726px #fff, 270px 9px #fff,
    1419px 855px #fff, 1159px 1050px #fff, 612px 1396px #fff, 698px 849px #fff,
    36px 345px #fff, 561px 569px #fff, 1948px 123px #fff, 1035px 1986px #fff,
    1855px 941px #fff, 791px 576px #fff, 624px 1729px #fff, 17px 1970px #fff,
    103px 1364px #fff, 1519px 766px #fff, 15px 73px #fff, 1765px 1771px #fff,
    1056px 1275px #fff, 1977px 1117px #fff, 525px 224px #fff, 1518px 806px #fff,
    1464px 265px #fff, 784px 607px #fff, 606px 1481px #fff, 1746px 936px #fff,
    873px 981px #fff, 271px 1240px #fff, 1616px 1642px #fff, 776px 1842px #fff,
    1325px 1221px #fff, 389px 1180px #fff, 1057px 513px #fff, 1072px 1486px #fff,
    1902px 285px #fff, 1952px 329px #fff, 1426px 919px #fff, 1803px 1313px #fff,
    113px 307px #fff, 1276px 915px #fff, 1935px 19px #fff, 933px 1197px #fff,
    369px 1938px #fff, 1942px 689px #fff, 433px 1456px #fff, 1672px 1355px #fff,
    565px 1551px #fff, 4px 878px #fff, 1424px 789px #fff, 835px 19px #fff,
    541px 1753px #fff, 528px 1442px #fff, 257px 943px #fff, 577px 174px #fff,
    235px 283px #fff, 1347px 422px #fff, 551px 1825px #fff, 67px 345px #fff,
    250px 1141px #fff, 1021px 276px #fff, 1865px 51px #fff, 1265px 1535px #fff,
    960px 1697px #fff, 1375px 1977px #fff, 1028px 1363px #fff,
    1355px 1966px #fff, 1982px 714px #fff, 237px 1269px #fff, 1799px 1611px #fff,
    614px 195px #fff, 1386px 1501px #fff, 375px 1834px #fff, 156px 1296px #fff,
    541px 677px #fff, 2000px 570px #fff, 456px 1484px #fff, 203px 1598px #fff,
    1258px 319px #fff, 1006px 1586px #fff, 467px 494px #fff, 86px 819px #fff,
    1636px 480px #fff, 1942px 773px #fff, 908px 1318px #fff, 1566px 834px #fff,
    1079px 1733px #fff, 764px 1089px #fff, 1570px 1282px #fff, 1122px 516px #fff,
    350px 490px #fff, 217px 603px #fff, 780px 386px #fff, 1137px 1305px #fff,
    1355px 56px #fff, 666px 42px #fff, 423px 373px #fff, 514px 1197px #fff,
    532px 461px #fff, 468px 473px #fff, 1705px 1806px #fff, 1874px 1837px #fff,
    170px 177px #fff, 1144px 1478px #fff, 550px 1163px #fff, 758px 964px #fff,
    1804px 1052px #fff, 277px 948px #fff, 469px 1359px #fff, 1471px 1362px #fff,
    168px 448px #fff, 367px 1241px #fff, 1082px 204px #fff, 540px 1220px #fff,
    614px 681px #fff, 1312px 33px #fff, 1213px 178px #fff, 793px 369px #fff,
    1680px 725px #fff, 1348px 1190px #fff, 798px 662px #fff, 1074px 1969px #fff,
    1174px 1538px #fff, 1891px 550px #fff, 235px 83px #fff, 178px 1850px #fff,
    479px 1110px #fff, 70px 753px #fff, 1083px 106px #fff, 1265px 1946px #fff,
    711px 1015px #fff, 632px 1057px #fff, 307px 1215px #fff, 1231px 734px #fff,
    1345px 81px #fff, 671px 8px #fff, 121px 802px #fff, 1874px 1835px #fff,
    552px 259px #fff, 464px 989px #fff, 346px 514px #fff, 235px 1247px #fff,
    174px 596px #fff, 1459px 549px #fff, 1695px 233px #fff, 619px 1125px #fff,
    140px 1506px #fff, 1715px 992px #fff, 143px 1632px #fff, 827px 1304px #fff,
    1655px 777px #fff, 697px 1400px #fff, 444px 1956px #fff, 44px 490px #fff,
    1855px 1282px #fff, 26px 165px #fff, 992px 713px #fff, 731px 1160px #fff,
    441px 606px #fff, 719px 190px #fff, 1649px 1806px #fff, 845px 487px #fff,
    1094px 632px #fff, 187px 1008px #fff, 412px 1068px #fff, 1637px 1387px #fff,
    748px 1571px #fff, 1255px 1156px #fff, 1704px 1724px #fff, 1288px 575px #fff,
    671px 1367px #fff, 1187px 1232px #fff, 97px 1992px #fff, 1812px 520px #fff,
    1703px 1272px #fff, 998px 983px #fff, 1180px 440px #fff, 61px 1469px #fff,
    1481px 214px #fff, 1066px 61px #fff, 590px 622px #fff;
  animation: animStar 100s linear infinite;
}
#stars2:after {
  content: ' ';
  position: absolute;
  top: 2000px;
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 645px 82px #fff, 1630px 477px #fff, 881px 1908px #fff,
    1673px 334px #fff, 740px 1761px #fff, 52px 1648px #fff, 34px 1902px #fff,
    767px 1393px #fff, 1278px 788px #fff, 456px 632px #fff, 1764px 470px #fff,
    311px 1276px #fff, 1205px 433px #fff, 21px 962px #fff, 1821px 575px #fff,
    1968px 1192px #fff, 1271px 1939px #fff, 1009px 712px #fff,
    1076px 1033px #fff, 712px 852px #fff, 1817px 1726px #fff, 270px 9px #fff,
    1419px 855px #fff, 1159px 1050px #fff, 612px 1396px #fff, 698px 849px #fff,
    36px 345px #fff, 561px 569px #fff, 1948px 123px #fff, 1035px 1986px #fff,
    1855px 941px #fff, 791px 576px #fff, 624px 1729px #fff, 17px 1970px #fff,
    103px 1364px #fff, 1519px 766px #fff, 15px 73px #fff, 1765px 1771px #fff,
    1056px 1275px #fff, 1977px 1117px #fff, 525px 224px #fff, 1518px 806px #fff,
    1464px 265px #fff, 784px 607px #fff, 606px 1481px #fff, 1746px 936px #fff,
    873px 981px #fff, 271px 1240px #fff, 1616px 1642px #fff, 776px 1842px #fff,
    1325px 1221px #fff, 389px 1180px #fff, 1057px 513px #fff, 1072px 1486px #fff,
    1902px 285px #fff, 1952px 329px #fff, 1426px 919px #fff, 1803px 1313px #fff,
    113px 307px #fff, 1276px 915px #fff, 1935px 19px #fff, 933px 1197px #fff,
    369px 1938px #fff, 1942px 689px #fff, 433px 1456px #fff, 1672px 1355px #fff,
    565px 1551px #fff, 4px 878px #fff, 1424px 789px #fff, 835px 19px #fff,
    541px 1753px #fff, 528px 1442px #fff, 257px 943px #fff, 577px 174px #fff,
    235px 283px #fff, 1347px 422px #fff, 551px 1825px #fff, 67px 345px #fff,
    250px 1141px #fff, 1021px 276px #fff, 1865px 51px #fff, 1265px 1535px #fff,
    960px 1697px #fff, 1375px 1977px #fff, 1028px 1363px #fff,
    1355px 1966px #fff, 1982px 714px #fff, 237px 1269px #fff, 1799px 1611px #fff,
    614px 195px #fff, 1386px 1501px #fff, 375px 1834px #fff, 156px 1296px #fff,
    541px 677px #fff, 2000px 570px #fff, 456px 1484px #fff, 203px 1598px #fff,
    1258px 319px #fff, 1006px 1586px #fff, 467px 494px #fff, 86px 819px #fff,
    1636px 480px #fff, 1942px 773px #fff, 908px 1318px #fff, 1566px 834px #fff,
    1079px 1733px #fff, 764px 1089px #fff, 1570px 1282px #fff, 1122px 516px #fff,
    350px 490px #fff, 217px 603px #fff, 780px 386px #fff, 1137px 1305px #fff,
    1355px 56px #fff, 666px 42px #fff, 423px 373px #fff, 514px 1197px #fff,
    532px 461px #fff, 468px 473px #fff, 1705px 1806px #fff, 1874px 1837px #fff,
    170px 177px #fff, 1144px 1478px #fff, 550px 1163px #fff, 758px 964px #fff,
    1804px 1052px #fff, 277px 948px #fff, 469px 1359px #fff, 1471px 1362px #fff,
    168px 448px #fff, 367px 1241px #fff, 1082px 204px #fff, 540px 1220px #fff,
    614px 681px #fff, 1312px 33px #fff, 1213px 178px #fff, 793px 369px #fff,
    1680px 725px #fff, 1348px 1190px #fff, 798px 662px #fff, 1074px 1969px #fff,
    1174px 1538px #fff, 1891px 550px #fff, 235px 83px #fff, 178px 1850px #fff,
    479px 1110px #fff, 70px 753px #fff, 1083px 106px #fff, 1265px 1946px #fff,
    711px 1015px #fff, 632px 1057px #fff, 307px 1215px #fff, 1231px 734px #fff,
    1345px 81px #fff, 671px 8px #fff, 121px 802px #fff, 1874px 1835px #fff,
    552px 259px #fff, 464px 989px #fff, 346px 514px #fff, 235px 1247px #fff,
    174px 596px #fff, 1459px 549px #fff, 1695px 233px #fff, 619px 1125px #fff,
    140px 1506px #fff, 1715px 992px #fff, 143px 1632px #fff, 827px 1304px #fff,
    1655px 777px #fff, 697px 1400px #fff, 444px 1956px #fff, 44px 490px #fff,
    1855px 1282px #fff, 26px 165px #fff, 992px 713px #fff, 731px 1160px #fff,
    441px 606px #fff, 719px 190px #fff, 1649px 1806px #fff, 845px 487px #fff,
    1094px 632px #fff, 187px 1008px #fff, 412px 1068px #fff, 1637px 1387px #fff,
    748px 1571px #fff, 1255px 1156px #fff, 1704px 1724px #fff, 1288px 575px #fff,
    671px 1367px #fff, 1187px 1232px #fff, 97px 1992px #fff, 1812px 520px #fff,
    1703px 1272px #fff, 998px 983px #fff, 1180px 440px #fff, 61px 1469px #fff,
    1481px 214px #fff, 1066px 61px #fff, 590px 622px #fff;
}

#stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 1850px 1756px #fff, 1023px 216px #fff, 145px 814px #fff,
    675px 1677px #fff, 1779px 1257px #fff, 765px 1157px #fff, 436px 1473px #fff,
    1389px 215px #fff, 1395px 447px #fff, 1908px 1513px #fff, 1693px 1293px #fff,
    1425px 1018px #fff, 556px 1783px #fff, 394px 74px #fff, 740px 872px #fff,
    1954px 751px #fff, 1481px 113px #fff, 1132px 1503px #fff, 1740px 1009px #fff,
    254px 203px #fff, 1919px 1578px #fff, 445px 890px #fff, 1108px 715px #fff,
    1233px 128px #fff, 867px 57px #fff, 503px 460px #fff, 1917px 891px #fff,
    1464px 1949px #fff, 1462px 1666px #fff, 826px 615px #fff, 743px 1290px #fff,
    1864px 1685px #fff, 909px 619px #fff, 1045px 896px #fff, 426px 1188px #fff,
    388px 374px #fff, 1397px 941px #fff, 1246px 1484px #fff, 426px 900px #fff,
    852px 1618px #fff, 38px 1786px #fff, 727px 980px #fff, 225px 1157px #fff,
    803px 582px #fff, 1631px 1797px #fff, 149px 104px #fff, 1892px 141px #fff,
    1130px 685px #fff, 702px 1807px #fff, 629px 1642px #fff, 541px 522px #fff,
    1708px 1176px #fff, 1671px 1388px #fff, 460px 729px #fff, 1907px 694px #fff,
    12px 941px #fff, 49px 1255px #fff, 1867px 409px #fff, 559px 73px #fff,
    1609px 107px #fff, 1667px 1105px #fff, 1723px 569px #fff, 1689px 1172px #fff,
    895px 1195px #fff, 198px 737px #fff, 1733px 1585px #fff, 575px 369px #fff,
    1382px 1961px #fff, 1917px 221px #fff, 983px 544px #fff, 1429px 1191px #fff,
    1373px 973px #fff, 571px 1061px #fff, 749px 1884px #fff, 1473px 1084px #fff,
    936px 1947px #fff, 168px 1730px #fff, 1200px 1312px #fff, 1567px 815px #fff,
    717px 985px #fff, 1097px 323px #fff, 1901px 1672px #fff, 812px 461px #fff,
    1458px 1033px #fff, 476px 520px #fff, 260px 1098px #fff, 661px 1183px #fff,
    1430px 1713px #fff, 894px 220px #fff, 931px 9px #fff, 579px 1587px #fff,
    632px 1558px #fff, 1821px 604px #fff, 1232px 563px #fff, 309px 430px #fff,
    1222px 1361px #fff, 173px 413px #fff, 1215px 1058px #fff, 240px 518px #fff,
    1379px 609px #fff;
  animation: animStar 150s linear infinite;
}
#stars3:after {
  content: ' ';
  position: absolute;
  top: 2000px;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 1850px 1756px #fff, 1023px 216px #fff, 145px 814px #fff,
    675px 1677px #fff, 1779px 1257px #fff, 765px 1157px #fff, 436px 1473px #fff,
    1389px 215px #fff, 1395px 447px #fff, 1908px 1513px #fff, 1693px 1293px #fff,
    1425px 1018px #fff, 556px 1783px #fff, 394px 74px #fff, 740px 872px #fff,
    1954px 751px #fff, 1481px 113px #fff, 1132px 1503px #fff, 1740px 1009px #fff,
    254px 203px #fff, 1919px 1578px #fff, 445px 890px #fff, 1108px 715px #fff,
    1233px 128px #fff, 867px 57px #fff, 503px 460px #fff, 1917px 891px #fff,
    1464px 1949px #fff, 1462px 1666px #fff, 826px 615px #fff, 743px 1290px #fff,
    1864px 1685px #fff, 909px 619px #fff, 1045px 896px #fff, 426px 1188px #fff,
    388px 374px #fff, 1397px 941px #fff, 1246px 1484px #fff, 426px 900px #fff,
    852px 1618px #fff, 38px 1786px #fff, 727px 980px #fff, 225px 1157px #fff,
    803px 582px #fff, 1631px 1797px #fff, 149px 104px #fff, 1892px 141px #fff,
    1130px 685px #fff, 702px 1807px #fff, 629px 1642px #fff, 541px 522px #fff,
    1708px 1176px #fff, 1671px 1388px #fff, 460px 729px #fff, 1907px 694px #fff,
    12px 941px #fff, 49px 1255px #fff, 1867px 409px #fff, 559px 73px #fff,
    1609px 107px #fff, 1667px 1105px #fff, 1723px 569px #fff, 1689px 1172px #fff,
    895px 1195px #fff, 198px 737px #fff, 1733px 1585px #fff, 575px 369px #fff,
    1382px 1961px #fff, 1917px 221px #fff, 983px 544px #fff, 1429px 1191px #fff,
    1373px 973px #fff, 571px 1061px #fff, 749px 1884px #fff, 1473px 1084px #fff,
    936px 1947px #fff, 168px 1730px #fff, 1200px 1312px #fff, 1567px 815px #fff,
    717px 985px #fff, 1097px 323px #fff, 1901px 1672px #fff, 812px 461px #fff,
    1458px 1033px #fff, 476px 520px #fff, 260px 1098px #fff, 661px 1183px #fff,
    1430px 1713px #fff, 894px 220px #fff, 931px 9px #fff, 579px 1587px #fff,
    632px 1558px #fff, 1821px 604px #fff, 1232px 563px #fff, 309px 430px #fff,
    1222px 1361px #fff, 173px 413px #fff, 1215px 1058px #fff, 240px 518px #fff,
    1379px 609px #fff;
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}

.moon-card {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-transform: uppercase;
  height: 100%;
  gap: 1rem;
  position: relative;
  h2 {
    font-size: var(--h2);
    letter-spacing: 3px;
  }
  h3 {
    font-size: var(--h3);
    letter-spacing: 3px;
  }
  .moon-info-container {
    display: flex;
    flex-direction: column;
    gap: 5rem;
    .moon-info {
      display: flex;
      justify-content: space-between;
      gap: 10rem;
      h3:nth-of-type(2) {
        opacity: 70%;
      }
    }
  }
}

.moon-img {
  width: 10rem;
  height: 10rem;
  border: 2px solid var(--white);
  box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background-color: var(--white);
  transform: translateZ(0);

  &::before {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--slate);
    width: 50%;
    height: 100%;
    animation: flip 2s 1s steps(2) infinite alternate;
  }
}

.moon-disc {
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
  animation: rotate 4s linear infinite;

  &::before,
  &::after {
    content: ' ';
    display: block;
    transform-style: preserve-3d;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: transform 4s;
    position: absolute;
    backface-visibility: hidden;
  }
  &::before {
    background-color: var(--slate);
  }
  &::after {
    background-color: var(--white);
    transform: rotateY(180deg);
  }
}

@keyframes rotate {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

@keyframes flip {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}

@media screen and (max-width: 400px) {
  .moon-card .moon-info-container {
    width: 100%;
  }
}

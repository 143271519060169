.weather-app {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed; // to deal with mobile vh address bar issue
  height: 100%; //
  width: 100%;
  .loading-msg {
    color: white;
    font-size: var(--h3);
    letter-spacing: 1px;
  }
  .date {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .weather-cards {
    display: flex;
    align-items: stretch;
    justify-content: center;
    gap: 3rem;
    height: 100%;
  }
}

@media screen and (max-width: 600px) {
  .weather-app {
    padding: 0;
    .weather-cards {
      margin: 0;
      width: 100%;
    }
  }
}

.sun-card {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-transform: uppercase;
  height: 100%;
  gap: 1rem;
  position: relative;
  h2 {
    font-size: var(--h2);
    letter-spacing: 3px;
  }
  h3 {
    font-size: var(--h3);
    letter-spacing: 3px;
  }
  .sun-info-container {
    display: flex;
    flex-direction: column;
    gap: 5rem;
    .sun-info {
      display: flex;
      justify-content: space-between;
      gap: 10rem;
      h3:nth-of-type(2) {
        opacity: 70%;
      }
    }
  }
}

@keyframes ray_anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.sun-box {
  position: relative;
  width: 10rem;
  height: 10rem;
  background: white;
  inset: 0;
  border-radius: 100%;
  box-shadow: 0px 0px 50px 15px rgba(255, 255, 255, 1);
  opacity: 0.9;
  .ray-box {
    position: absolute;
    margin: auto;
    inset: 0;
    width: 7rem;
    animation: ray_anim 60s linear infinite;
  }
  .ray {
    background: linear-gradient(transparent, white, transparent);
    border-radius: 80% 80% 0 0;
    position: absolute;
    opacity: 0.15;
    &.tleft,
    &.tright,
    &.center,
    &.bleft,
    &.bright {
      height: 170px;
      width: 30px;
    }
    &.tleftS,
    &.trightS,
    &.centerS,
    &.bleftS,
    &.brightS {
      height: 11rem;
      width: 1rem;
    }
    &.tleft {
      transform: rotate(115deg);
      top: -9rem;
      left: -9.5rem;
    }
    &.tright {
      transform: rotate(245deg);
      top: -9rem;
      right: -9.5rem;
    }
    &.center {
      transform: rotate(180deg);
      top: -16rem;
      right: 2rem;
    }
    &.bleft {
      transform: rotate(40deg);
      top: 6rem;
      left: -6.5rem;
    }
    &.bright {
      transform: rotate(320deg);
      top: 6rem;
      right: -6.5rem;
    }
    &.tleftS {
      transform: rotate(145deg);
      top: -9rem;
      left: -3rem;
    }
    &.trightS {
      transform: rotate(215deg);
      top: -9rem;
      right: -3rem;
    }
    &.centerS {
      transform: rotate(360deg);
      bottom: -10.7rem;
      right: 3rem;
    }
    &.bleftS {
      transform: rotate(75deg);
      top: 1.5rem;
      left: -7rem;
    }
    &.brightS {
      transform: rotate(285deg);
      top: 1.5rem;
      right: -7rem;
    }
  }
}

@media screen and (max-width: 400px) {
  .sun-card .sun-info-container {
    width: 100%;
  }
}

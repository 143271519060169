@font-face {
  font-family: SPB;
  src: url("/public/assets/SofiaProSemiBold.otf");
}

@font-face {
  font-family: SPR;
  src: url("/public/assets/SofiaProRegular.otf");
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  font-family: SPB, SPR, sans-serif;
}

html {
  font-size: 10px;
  height: 100%;
}

body {
  overflow: hidden;
  background: -webkit-gradient(linear, left top, left bottom, from(#021545), color-stop(#0c5aaa), color-stop(#6795c5), to(#f8d0b0));
  background: linear-gradient(#021545, #0c5aaa, #6795c5, #f8d0b0);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  cursor: pointer;
}

#root {
  height: 100vh;
}

.landing {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 2rem;
  color: var(--white);
}

.landing h2 {
  font-size: var(--h2);
  letter-spacing: 2px;
}

.landing h2:nth-of-type(2) {
  opacity: 70%;
}

.landing h3 {
  background-color: rgba(255, 255, 255, 0.3);
  padding: 1rem 1.5rem;
  border-radius: 2rem;
  letter-spacing: 1px;
  color: var(--offwhite);
}

.landing .info-card-landing {
  background-color: rgba(255, 255, 255, 0.3);
  padding: 1rem 1.5rem;
  border-radius: 2rem;
}

.landing .info-card-landing .icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}

.landing .info-card-landing svg {
  width: 2rem;
  height: 100%;
  fill: transparent;
}

.card {
  display: flex;
  flex-direction: column;
  color: var(--white);
  width: 100vw;
  padding: 5rem;
  position: relative;
  &.current {
    background: linear-gradient(#021545, #0c5aaa, #6795c5, #f8d0b0);
  }
  &.moon {
    background: linear-gradient(#010903, #002161, #5d497c);
    position: relative;
    overflow: hidden;
    .stars-container {
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      span {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  &.sun {
    background: linear-gradient(#1f476a, #377abe, #b8bfc5, #ddb285, #ff5c3d);
  }
  .current-main {
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 100%;
    width: 100%;
    .current-card {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      flex: 1;
    }
    .current-date {
      text-transform: uppercase;
      h2 {
        font-size: var(--h2);
        letter-spacing: 3px;
        line-height: 1.5em;
        margin-bottom: 1rem;
      }
      h3 {
        font-family: SPR;
        font-size: var(--h3);

        opacity: 70%;
        letter-spacing: 3px;
      }
    }
    .current-temp {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 0;
      gap: 1rem;
      h1 {
        line-height: 1em;
        font-size: var(--h1);
        position: relative;
        &::after {
          content: '\00b0';
          position: absolute;
          font-size: 0.5em;
          top: -3.5rem;
          right: -3rem;
        }
      }
      h2 {
        font-size: var(--h2);
        letter-spacing: 2px;
      }
      h3 {
        line-height: 1.5em;
        font-family: SPR;
        font-size: var(--h3);
        letter-spacing: 2px;
      }
    }
    .dates {
      display: flex;
      justify-content: space-evenly;
      margin: 0 auto;
      gap: 5rem;
      .day {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 9rem;
        height: 11rem;
        border-radius: 1rem;
        padding: 2rem;
        transition: ease 0.5s;
        box-shadow: -3px -3px 5px rgba(255, 255, 255, 0.2),
          3px 3px 5px rgba(0, 0, 0, 0.13);
        cursor: pointer;
        h2 {
          font-size: var(--h2);
          letter-spacing: 2px;
          &:first-of-type {
            opacity: 70%;
          }
        }
        h3 {
          font-size: var(--h3);
          letter-spacing: 2px;
        }
        &.active {
          box-shadow: inset -3px -3px 5px rgba(255, 255, 255, 0.2),
            inset 3px 3px 5px rgba(0, 0, 0, 0.13);
        }
      }
    }
    .precip-wind {
      display: flex;
      margin: 0 auto;
      gap: 5rem;
      .precip,
      .wind {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;
        h3 {
          font-size: var(--h3);
          letter-spacing: 1px;
        }
      }
    }
  }
  .tabs {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-top: 3rem;
    gap: 2rem;
    position: relative;
    .tab {
      display: flex;
      justify-content: center;
      text-align: center;
      padding: 0.7rem;
      width: 5.5rem;
      box-shadow: 0px 0px 15px var(--white);
      border-radius: 2rem;
      opacity: 1;
      cursor: pointer;
      &.inactive {
        transition: ease 0.3s;
        box-shadow: 0px 0px 5px var(--white);
        opacity: 70%;
        &:hover {
          box-shadow: 0px 0px 15px var(--white);
          opacity: 1;
        }
      }
    }
  }

  img {
    width: 7rem;
  }

  .reload {
    cursor: pointer;
    opacity: 70%;
    transition: ease 0.3s;
    &:hover {
      opacity: 1;
    }
  }
}

@media screen and (max-width: 600px) {
  .card {
    .current-main .dates {
      margin: 0;
      gap: 0;
    }
  }
}

@media screen and (max-width: 420px) {
  .card {
    padding: 3rem 3rem 4rem 3rem;
    .current-main {
      .current-temp h1 {
        font-size: 10.8rem;
        &::after {
          content: '\00b0';
          position: absolute;
          font-size: 0.5em;
          top: -3rem;
          right: -1.5rem;
        }
      }
      .dates {
        justify-content: space-evenly;
        gap: 1rem;
        flex-wrap: wrap;
      }
    }

    img {
      width: 6rem;
    }
    .tabs {
      margin-top: 0rem;
    }
  }
}

@media screen and (min-width: 601px) {
  .card .current-main {
    .precip-wind,
    .dates {
      margin: 0 auto;
      gap: 5rem;
    }
  }
}

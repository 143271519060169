.moon-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-transform: uppercase;
  height: 100%;
  gap: 1rem;
  position: relative;
}

.moon-card h2 {
  font-size: var(--h2);
  letter-spacing: 3px;
}

.moon-card h3 {
  font-size: var(--h3);
  letter-spacing: 3px;
}

.moon-card .moon-info-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 5rem;
}

.moon-card .moon-info-container .moon-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 10rem;
}

.moon-card .moon-info-container .moon-info h3:nth-of-type(2) {
  opacity: 70%;
}

.moon-img {
  width: 10rem;
  height: 10rem;
  border: 2px solid var(--white);
  -webkit-box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.5);
          box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background-color: var(--white);
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}

.moon-img::before {
  content: ' ';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--slate);
  width: 50%;
  height: 100%;
  -webkit-animation: flip 2s 1s steps(2) infinite alternate;
          animation: flip 2s 1s steps(2) infinite alternate;
}

.moon-disc {
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  width: 100%;
  height: 100%;
  -webkit-animation: rotate 4s linear infinite;
          animation: rotate 4s linear infinite;
}

.moon-disc::before, .moon-disc::after {
  content: ' ';
  display: block;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-transition: -webkit-transform 4s;
  transition: -webkit-transform 4s;
  transition: transform 4s;
  transition: transform 4s, -webkit-transform 4s;
  position: absolute;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.moon-disc::before {
  background-color: var(--slate);
}

.moon-disc::after {
  background-color: var(--white);
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  100% {
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  100% {
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg);
  }
}

@-webkit-keyframes flip {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}

@keyframes flip {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}

@media screen and (max-width: 400px) {
  .moon-card .moon-info-container {
    width: 100%;
  }
}

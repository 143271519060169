.card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  color: var(--white);
  width: 100vw;
  padding: 5rem;
  position: relative;
}

.card.current {
  background: -webkit-gradient(linear, left top, left bottom, from(#021545), color-stop(#0c5aaa), color-stop(#6795c5), to(#f8d0b0));
  background: linear-gradient(#021545, #0c5aaa, #6795c5, #f8d0b0);
}

.card.moon {
  background: -webkit-gradient(linear, left top, left bottom, from(#010903), color-stop(#002161), to(#5d497c));
  background: linear-gradient(#010903, #002161, #5d497c);
  position: relative;
  overflow: hidden;
}

.card.moon .stars-container {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
}

.card.moon .stars-container span {
  position: absolute;
  top: 0;
  left: 0;
}

.card.sun {
  background: -webkit-gradient(linear, left top, left bottom, from(#1f476a), color-stop(#377abe), color-stop(#b8bfc5), color-stop(#ddb285), to(#ff5c3d));
  background: linear-gradient(#1f476a, #377abe, #b8bfc5, #ddb285, #ff5c3d);
}

.card .current-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
  height: 100%;
  width: 100%;
}

.card .current-main .current-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.card .current-main .current-date {
  text-transform: uppercase;
}

.card .current-main .current-date h2 {
  font-size: var(--h2);
  letter-spacing: 3px;
  line-height: 1.5em;
  margin-bottom: 1rem;
}

.card .current-main .current-date h3 {
  font-family: SPR;
  font-size: var(--h3);
  opacity: 70%;
  letter-spacing: 3px;
}

.card .current-main .current-temp {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 0;
      -ms-flex: 0;
          flex: 0;
  gap: 1rem;
}

.card .current-main .current-temp h1 {
  line-height: 1em;
  font-size: var(--h1);
  position: relative;
}

.card .current-main .current-temp h1::after {
  content: '\00b0';
  position: absolute;
  font-size: 0.5em;
  top: -3.5rem;
  right: -3rem;
}

.card .current-main .current-temp h2 {
  font-size: var(--h2);
  letter-spacing: 2px;
}

.card .current-main .current-temp h3 {
  line-height: 1.5em;
  font-family: SPR;
  font-size: var(--h3);
  letter-spacing: 2px;
}

.card .current-main .dates {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  margin: 0 auto;
  gap: 5rem;
}

.card .current-main .dates .day {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 9rem;
  height: 11rem;
  border-radius: 1rem;
  padding: 2rem;
  -webkit-transition: ease 0.5s;
  transition: ease 0.5s;
  -webkit-box-shadow: -3px -3px 5px rgba(255, 255, 255, 0.2), 3px 3px 5px rgba(0, 0, 0, 0.13);
          box-shadow: -3px -3px 5px rgba(255, 255, 255, 0.2), 3px 3px 5px rgba(0, 0, 0, 0.13);
  cursor: pointer;
}

.card .current-main .dates .day h2 {
  font-size: var(--h2);
  letter-spacing: 2px;
}

.card .current-main .dates .day h2:first-of-type {
  opacity: 70%;
}

.card .current-main .dates .day h3 {
  font-size: var(--h3);
  letter-spacing: 2px;
}

.card .current-main .dates .day.active {
  -webkit-box-shadow: inset -3px -3px 5px rgba(255, 255, 255, 0.2), inset 3px 3px 5px rgba(0, 0, 0, 0.13);
          box-shadow: inset -3px -3px 5px rgba(255, 255, 255, 0.2), inset 3px 3px 5px rgba(0, 0, 0, 0.13);
}

.card .current-main .precip-wind {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  gap: 5rem;
}

.card .current-main .precip-wind .precip,
.card .current-main .precip-wind .wind {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 1rem;
}

.card .current-main .precip-wind .precip h3,
.card .current-main .precip-wind .wind h3 {
  font-size: var(--h3);
  letter-spacing: 1px;
}

.card .tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 auto;
  margin-top: 3rem;
  gap: 2rem;
  position: relative;
}

.card .tabs .tab {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  padding: 0.7rem;
  width: 5.5rem;
  -webkit-box-shadow: 0px 0px 15px var(--white);
          box-shadow: 0px 0px 15px var(--white);
  border-radius: 2rem;
  opacity: 1;
  cursor: pointer;
}

.card .tabs .tab.inactive {
  -webkit-transition: ease 0.3s;
  transition: ease 0.3s;
  -webkit-box-shadow: 0px 0px 5px var(--white);
          box-shadow: 0px 0px 5px var(--white);
  opacity: 70%;
}

.card .tabs .tab.inactive:hover {
  -webkit-box-shadow: 0px 0px 15px var(--white);
          box-shadow: 0px 0px 15px var(--white);
  opacity: 1;
}

.card img {
  width: 7rem;
}

.card .reload {
  cursor: pointer;
  opacity: 70%;
  -webkit-transition: ease 0.3s;
  transition: ease 0.3s;
}

.card .reload:hover {
  opacity: 1;
}

@media screen and (max-width: 600px) {
  .card .current-main .dates {
    margin: 0;
    gap: 0;
  }
}

@media screen and (max-width: 420px) {
  .card {
    padding: 3rem 3rem 4rem 3rem;
  }
  .card .current-main .current-temp h1 {
    font-size: 10.8rem;
  }
  .card .current-main .current-temp h1::after {
    content: '\00b0';
    position: absolute;
    font-size: 0.5em;
    top: -3rem;
    right: -1.5rem;
  }
  .card .current-main .dates {
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    gap: 1rem;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .card img {
    width: 6rem;
  }
  .card .tabs {
    margin-top: 0rem;
  }
}

@media screen and (min-width: 601px) {
  .card .current-main .precip-wind,
  .card .current-main .dates {
    margin: 0 auto;
    gap: 5rem;
  }
}

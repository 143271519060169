.sun-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-transform: uppercase;
  height: 100%;
  gap: 1rem;
  position: relative;
}

.sun-card h2 {
  font-size: var(--h2);
  letter-spacing: 3px;
}

.sun-card h3 {
  font-size: var(--h3);
  letter-spacing: 3px;
}

.sun-card .sun-info-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 5rem;
}

.sun-card .sun-info-container .sun-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 10rem;
}

.sun-card .sun-info-container .sun-info h3:nth-of-type(2) {
  opacity: 70%;
}

@-webkit-keyframes ray_anim {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes ray_anim {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.sun-box {
  position: relative;
  width: 10rem;
  height: 10rem;
  background: white;
  inset: 0;
  border-radius: 100%;
  -webkit-box-shadow: 0px 0px 50px 15px white;
          box-shadow: 0px 0px 50px 15px white;
  opacity: 0.9;
}

.sun-box .ray-box {
  position: absolute;
  margin: auto;
  inset: 0;
  width: 7rem;
  -webkit-animation: ray_anim 60s linear infinite;
          animation: ray_anim 60s linear infinite;
}

.sun-box .ray {
  background: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(white), to(transparent));
  background: linear-gradient(transparent, white, transparent);
  border-radius: 80% 80% 0 0;
  position: absolute;
  opacity: 0.15;
}

.sun-box .ray.tleft, .sun-box .ray.tright, .sun-box .ray.center, .sun-box .ray.bleft, .sun-box .ray.bright {
  height: 170px;
  width: 30px;
}

.sun-box .ray.tleftS, .sun-box .ray.trightS, .sun-box .ray.centerS, .sun-box .ray.bleftS, .sun-box .ray.brightS {
  height: 11rem;
  width: 1rem;
}

.sun-box .ray.tleft {
  -webkit-transform: rotate(115deg);
          transform: rotate(115deg);
  top: -9rem;
  left: -9.5rem;
}

.sun-box .ray.tright {
  -webkit-transform: rotate(245deg);
          transform: rotate(245deg);
  top: -9rem;
  right: -9.5rem;
}

.sun-box .ray.center {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  top: -16rem;
  right: 2rem;
}

.sun-box .ray.bleft {
  -webkit-transform: rotate(40deg);
          transform: rotate(40deg);
  top: 6rem;
  left: -6.5rem;
}

.sun-box .ray.bright {
  -webkit-transform: rotate(320deg);
          transform: rotate(320deg);
  top: 6rem;
  right: -6.5rem;
}

.sun-box .ray.tleftS {
  -webkit-transform: rotate(145deg);
          transform: rotate(145deg);
  top: -9rem;
  left: -3rem;
}

.sun-box .ray.trightS {
  -webkit-transform: rotate(215deg);
          transform: rotate(215deg);
  top: -9rem;
  right: -3rem;
}

.sun-box .ray.centerS {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
  bottom: -10.7rem;
  right: 3rem;
}

.sun-box .ray.bleftS {
  -webkit-transform: rotate(75deg);
          transform: rotate(75deg);
  top: 1.5rem;
  left: -7rem;
}

.sun-box .ray.brightS {
  -webkit-transform: rotate(285deg);
          transform: rotate(285deg);
  top: 1.5rem;
  right: -7rem;
}

@media screen and (max-width: 400px) {
  .sun-card .sun-info-container {
    width: 100%;
  }
}

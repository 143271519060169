.info-card {
  background-color: rgba(255, 255, 255, 0.3);
  padding: 1rem 1.5rem;
  border-radius: 2rem;
  position: absolute;
  bottom: 5.5rem;
  right: -3rem;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
  &.open {
    opacity: 1;
    pointer-events: all;
  }
  .icons {
    display: flex;
    gap: 1rem;
  }
  svg {
    width: 2rem;
    height: 100%;
    fill: transparent;
  }
}

.landing {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  color: var(--white);

  h2 {
    font-size: var(--h2);
    letter-spacing: 2px;
    &:nth-of-type(2) {
      opacity: 70%;
    }
  }

  h3 {
    background-color: rgba(255, 255, 255, 0.3);
    padding: 1rem 1.5rem;
    border-radius: 2rem;
    letter-spacing: 1px;
    color: var(--offwhite);
  }

  .info-card-landing {
    background-color: rgba(255, 255, 255, 0.3);
    padding: 1rem 1.5rem;
    border-radius: 2rem;
    .icons {
      display: flex;
      gap: 1rem;
    }
    svg {
      width: 2rem;
      height: 100%;
      fill: transparent;
    }
  }
}
